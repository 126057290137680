<template>
    <div class="m-3">
        <div class="fl-te-c mb-3">
            <h4>Purchase List</h4>
            <div class="btn-group ml-3">
                <validated-input placeholder="search" v-model="search">
                    <template #append>
                        <span><i class="fa fa-search" aria-hidden="true"></i></span>
                    </template>
                </validated-input>
                <drop-btn text="Filters" icon="fa fa-filter" size="md" dropdown-width="30r">
                    <div class="p-3">
                        <validated-ajax-vue-select name="Vendor" :url="vendorOptions"
                                                   label="Vendor"
                                                   v-model="vendor"/>
                        <validated-input type="date" label="Start Date" name="Start Date"
                                               v-model="start_date"/>
                        <validated-input type="date" label="End Date" name="End Date"
                                               v-model="end_date"/>
                    </div>
                </drop-btn>
            </div>
        </div>
        <div class="card bs-4 p-0 of-h">
            <h5 class="bb-1 p-3 mb-0">Purchase Info</h5>
            <tabs class="">
                <tab title="All" class="p-0">
                    <listing-component :start_date="start_date.toString()" :end_date="end_date.toString()"
                                       :vendor="vendor.toString()" :searches="search"
                                       :status="''"/>
                </tab>
                <tab title="Pending" class="p-0">
                    <listing-component :start_date="start_date.toString()" :end_date="end_date.toString()"
                                       :vendor="vendor.toString()" :searches="search"
                                       :status="'PA Approved'"/>
                </tab>
                <tab title="Completed" class="p-0">
                    <listing-component :start_date="start_date.toString()" :end_date="end_date.toString()"
                                       :vendor="vendor.toString()" :searches="search"
                                       :status="'FA Approved'"/>

                </tab>
            </tabs>
        </div>
    </div>
</template>

<script>
import urls from '../../../../data/urls';
import ListingComponent from '../../../FinanceAdmin/PurchaseEntry/PurchaseEntry/ListingComponent';

export default {
    name       : 'ViewPage',
    components : { ListingComponent },
    data () {
        return {
            loading       : false,
            start_date    : '',
            search        : '',
            end_date      : '',
            vendor        : '',
            status        : '',
            vendorOptions : urls.financeAdmin.vendorOptions,
            fields        : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'Purchase ID'
                },
                {
                    name      : 'order_id',
                    sortField : 'order_id',
                    title     : 'PO ID'
                },
                {
                    name      : 'vendor',
                    sortField : 'vendor',
                    title     : 'Vendor'
                },
                {
                    name      : 'created_date',
                    sortField : 'created_date',
                    title     : 'Date'
                },
                {
                    name      : 'created_by',
                    sortField : 'created_by',
                    title     : 'Created By'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        viewDetails (rowData) {
            this.$router.push('/purchase/entry/' + rowData.entry_id + '/details/');
        }
    }
};
</script>
