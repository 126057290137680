<template>
    <plane-vue-table :showSearchBox="false" title="" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                     :page-number="false" :extra-params="{ start_date, vendor, end_date, searches, status }">
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn color="primary" class="btn-basic-b" size="xs" @click="viewDetails(rowData)" text="View"/>
            </div>
        </template>
    </plane-vue-table>
</template>

<script>
import urls from '../../../../data/urls';

export default {
    name  : 'ListingComponent',
    props : {
        start_date : { type : String },
        end_date   : { type : String },
        vendor     : { type : String },
        status     : { type : String },
        searches   : { type : String }
    },
    data () {
        return {
            loading       : false,
            listUrl       : urls.financeAdmin.purchaseEntry.list,
            vendorOptions : urls.financeAdmin.vendorOptions,
            fields        : [
                {
                    name      : 'entry_id',
                    sortField : 'entry_id',
                    title     : 'Purchase ID'
                },
                {
                    name      : 'order_id',
                    sortField : 'order_id',
                    title     : 'PO ID'
                },
                {
                    name      : 'vendor',
                    sortField : 'vendor',
                    title     : 'Vendor'
                },
                {
                    name      : 'date',
                    sortField : 'date',
                    title     : 'Date'
                },
                {
                    name      : 'created_by',
                    sortField : 'created_by',
                    title     : 'Created By'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        viewDetails (rowData) {
            this.$router.push('/finance/entry/' + rowData.entry_id + '/details/');
        }
    }
};
</script>
